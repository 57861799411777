/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './layout.css';
import DarkModeToggle from './DarkModeToggle';

const Layout = ({ children }) => {
  return (
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 640,
        padding: `1.0875rem 1.45rem`,
      }}
    >
      <header className="main-header flex-between">
        <h3 className="brand-title">
          <Link to="/">uku.dev</Link>
        </h3>
        <DarkModeToggle />
      </header>
      <main>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
