import React, { useEffect, useState } from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import { FiSun as Sun, FiMoon as Moon } from 'react-icons/fi';
import Helmet from 'react-helmet';

const DarkModeToggle = () => {
  const [onClient, setIfOnClient] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIfOnClient(true);
    }
  }, []);
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <>
          <Helmet
            meta={[
              {
                name: 'theme-color',
                content: theme === 'dark' ? '#282c35' : '#ffffff',
              },
            ]}
          />
          {onClient && (
            <button
              className="dark-mode-toggle"
              aria-hidden="true"
              onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
            >
              {theme === 'dark' ? <Moon size="1.2em" /> : <Sun size="1.2em" />}
            </button>
          )}
        </>
      )}
    </ThemeToggler>
  );
};

export default DarkModeToggle;
